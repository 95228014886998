<template>
  <span>
    <button
      v-b-tooltip.hovern
      style="font-size: 11.5px;padding: .707rem 1rem;"
      title="A atualização será feita para o Período de apuração informado e para as empresas selecionadas. Cada atualização consumirá um crédito do saldo em conta."
      class="btn btn-sm btn-outline-primary"
      @click="abrirModal()"
    >
      <span>Atualizar DCTFWeb por PA</span>
    </button>
    <b-modal
      ref="modalAtualizacaoPa"
      size="xl"
      :hide-header-close="true"
      modal-class="tamanho-modal"
    >
      <template #modal-title>
        <b-row>
          <b-col>
            <h4>Atualização de DCTFWeb por Empresa e Período de apuração</h4>
          </b-col>
        </b-row>
        <fieldset
          class="font-weight-bold text-muted small d-flex flex-row align-items-center"
          style="gap: .3rem;"
        >
          <span>Selecione as empresas e período de apuração que deseja atualizar</span>
          <feather-icon
            v-b-tooltip.hover
            title="Ao confirmar a atualização será descontado um crédito do seu saldo em conta para cada empresa selecionada."
            icon="AlertCircleIcon"
            size="12"
            :class="'text-primary'"
          />
        </fieldset>
      </template>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-success btn-none"
            style="margin-left: 0.3rem;"
            @click="openModalCreditos()"
          >
            Salvar
          </b-btn>
          <b-btn
            variant="none"
            size="sm"
            class="btn-outline-danger btn-none"
            style="margin-left: 0.3rem;"
            @click="fecharModal()"
          >
            Cancelar
          </b-btn>
        </div>
      </template>
      <div>
        <b-row
          class="d-flex align-items-end mb-2"
        >
          <b-col cols="auto">
            <h5>Selecione o período de apuração desejado</h5>
          </b-col>
          <b-col cols="auto">
            <b-form-input
              v-model="filtroCustomData"
              type="month"
              style="width: 12rem;"
              @change="mudarFiltroData($event)"
            />
          </b-col>
        </b-row>
        <vinculo-empresas
          v-model="empresasVinculadas"
          :cadastro-usuario="false"
          :visualizacao-opcoes="visualizacao"
          :tem-busca="true"
          :inverter-vincular-desvincular="true"
          :filtros-vinculadas="filtrosVinculadas"
          :filtros-nao-vinculadas="filtrosNaoVinculadas"
        /></div>
    </b-modal>
    <consumo-credito
      ref="atualizarManual"
      tipo-rotina="DCTFWeb"
      @atualizar="atualizarManual()"
      @fechar="$refs.atualizarManual.$refs.consumoCredito.hide()"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :titulo-carregamento="'Aguarde!'"
      :descricao="`Estamos atualizando a DCTFWeb.`"
    />
  </span>
</template>

<style lang="scss">

.tamanho-modal {
  margin-left: 18rem;
  width: calc(100% - 18rem);
}

</style>

<script>
import moment from 'moment'
import api from '../../services/api'

export default {
  components: {
    VinculoEmpresas: () => import('@pilar/components/vinculo-empresas/VinculoEmpresas.vue'),
    ConsumoCredito: () => import('@/app/shared/components/modal-consumo-credito/ModalConsumoCredito.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
  },
  data() {
    return {
      filtroCustomData: '',
      visualizacao: {
        placeholderBusca: 'Busca por palavra chave e CNPJ/CPF',
        customTitle: 'Selecione a(s) empresa(s) que deseja atualizar a DCTFWeb',
        customSubtitle: 'As empresas listadas abaixo possuem a configuração da DCTFWeb marcada no cadastro de empresas em "Configuração de rotinas e-Cac',
        tituloVinculada: 'Empresas com DCTFWeb',
        tituloDesvinculada: 'Empresas que devem ser atualizadas',
        evento: this.getEmpresasComDctfwebFiltradas,
      },
      filtrosVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      filtrosNaoVinculadas: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial'], valor: null }],
      empresasVinculadas: {
        vinculadas: [],
        naoVinculadas: [],
        id: 0,
      },
      isLoading: false,
    }
  },
  created() {
    this.filtroCustomData = moment().subtract(1, 'month').format('yyyy-MM')
  },
  methods: {
    abrirModal() {
      api.getEmpresasComDctfweb()
        .then(payload => {
          this.empresasVinculadas = payload.data
          this.$refs.modalAtualizacaoPa.show()
        })
        .catch(() => {
          this.$erro('Não foi possível buscar as empresas vinculadas à DCTFWeb.')
        })
    },
    fecharModal() {
      this.$refs.modalAtualizacaoPa.hide()
    },
    openModalCreditos() {
      this.$emit('stacking', false)
      this.$nextTick(() => {
        this.$refs.atualizarManual.$refs.consumoCredito.show()
      })
    },
    atualizarManual() {
      if (this.empresasVinculadas.naoVinculadas.some(s => s)) {
        this.isLoading = true
        this.$refs.atualizarManual.$refs.consumoCredito.hide()
        this.$refs.modalAtualizacaoPa.hide()

        api.atualizarManual({
          empresasId: this.empresasVinculadas.naoVinculadas.map(m => m.id),
          competencia: this.filtroCustomData,
        })
          .then(payload => {
            this.$emit('atualizar-grid')
            this.verificarRetornos(payload.data)
          })
          .catch(err => {
            this.isLoading = false
            if (err.response.data.message.includes('Empresa(s) não atualizada(s):')) {
              this.openModalErro('Empresa(s) sem DCTFWeb ou com erro', err.response.data.message)
            } else {
              this.$erro('Não foi possível fazer a atualização. Tente novamente mais tarde!', err)
            }
            throw err
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$erro('Nenhuma empresa selecionada!')
      }
    },
    verificarRetornos(enumResult) {
      if (enumResult === 1) {
        this.$sucesso('DCTFWeb atualizada com sucesso!')
      } else if (enumResult === 3) {
        this.openModalErro('Limite atingido', 'O limite de emissão diária da DCTFWeb foi atingido para todos os certificados válidos disponíveis. Aguarde a liberação de novas consultas.')
      } else if (enumResult === 4) {
        this.openModalErro('Nenhum certificado encontrado', 'Nenhum certificado válido foi encontrado. A atualização manual não pode ser realizada.')
      } else if (enumResult === 5) {
        this.$erro('Saldo insuficiente para concluir a operação.')
      } else if (enumResult.includes('Empresa(s) não atualizada(s):')) {
        this.openModalErro('Empresa(s) sem DCTFWeb ou com erro', enumResult)
      } else {
        this.$erro('Houve um problema com a atualização. Tente novamente mais tarde!')
      }
    },
    openModalErro(title, html) {
      this.$swal({
        title: `<div class="text-danger">${title}!</div>`,
        html: `<div class="text-primary">${html}</div>`,
        icon: 'warning',
        iconColor: '#ea5455',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-outline-danger mr-50',
        },
        buttonsStyling: false,
      })
    },
    getEmpresasComDctfwebFiltradas(id, body) {
      return api.getEmpresasComDctfwebFiltradas(body)
    },
  },
}
</script>
